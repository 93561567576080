.login-facebook {
    background-color: #4c69ba;    
    border: 2px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    padding: 8px;
    text-decoration: none;
    width: 100%;    
    transition: background-color .3s,border-color .3s;
    -webkit-font-smoothing: antialiased;
    margin-top: 16px;
}
.poster {
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
  border-radius: 2px;
  border: 1px solid #000;
}

.poster img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.poster img:hover {
  transform: scale(1.1);
  transition: 0.8s ease-in-out;
}

.poster .overlay-order {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.poster .overlay-bonus {
  position: absolute;
  left: 8px;
  top: 12px;
}

body {
  font-family: "Nunito", sans-serif;
}

.ant-layout {
  background: #f0f2f5;
  color: rgba(0, 0, 0, 0.85);
}

.ant-layout-header {
  /* background: #f0f2f5; */
  /* rgb(38,222,129) */
  background: #fff;
  border-bottom: 1px solid #dedede;
  z-index: 99;
  padding: 0;
  height: 180px;
  width: 100%;
  line-height: unset;
}

.ant-layout-content {
  min-height: 80vh;
}

.ant-layout-footer {
  /* background: #f0f2f5; */
  background: #fff;
  border-top: 1px solid #dedede;
  padding: 0%;
  margin-top: 16px;
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-text:hover {
  background: #dedede;
}

.ant-input:disabled {
  color: rgba(0, 0, 0, 0.75);
}

.ant-list-vertical .ant-list-item-action > li {
  padding: 0;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 1);
}

.ant-btn-primary {
  background: #009432;
  border: 0;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #009432;
  border: 0;
  color: #fff;
  opacity: 0.75;
}

.ant-carousel .slick-dots li button {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: #000;
}

.ant-select-single.ant-select-open .ant-select-selection-item,
.ant-select-arrow {
  color: #fff;
}

.ant-rate-star-zero {
  color: #888;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #2ed573;
  border-bottom: 2px solid #2ed573;
} */
